<template>
	<div>
		<CCard class="p-4"> 
			<CRow><CButton size="sm" class="btn btn-link back-btn" 
				@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
			</CRow>
		
			<CRow >        
						<CCol lg="1">
							<template v-if="dataParams.vehicle_photo_s3 == 0">
								<img style="max-height: 100px; max-width:100px;" id="display_default_vehicle_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.vehicle_photo">
								<img style="max-height: 100px; max-width:100px;" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.vehicle_photo" v-else>
							</template>
							<template v-else>
								<img style="max-height: 100px; max-width:100px;" :src="dataParams.vehicle_photo_s3">
							</template>
								<!-- <img style="max-height: 100px; max-width:100px;" id="display_default_vehicle_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.vehicle_photo">
								<img style="max-height: 100px; max-width:100px;" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.vehicle_photo" v-else> -->
						
						</CCol> 
						<CCol lg="4">
						<span >
							<h3 class="mb-0 text-primary">
								{{dataParams.plate_no != "" && dataParams.plate_no != null ? dataParams.plate_no : "-"}} 
								</h3>
							<p>
							<small>{{dataParams.maker_model ? dataParams.maker_model.setting_name : "-"}} 
								{{dataParams.model ? dataParams.model.setting_name : "-" }} 
							 </small><br/>
							<em>{{dataParams.vehicle_type ? dataParams.vehicle_type.setting_name : "-"}}</em><br />
							<strong style="text-transform:uppercase; font-size:12px;color:green">{{dataParams.status ? dataParams.status : "-"}}</strong>
							</p>
						</span>
						</CCol>
						<!-- <CCol lg="7">
							<div style="text-align: right"> 
								<CDropdown 
											toggler-text=" Quick Add "  
											size="sm"
											color="success"
										
										>
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Renewal Reminder </i></CDropdownItem> 
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Service Reminder </i></CDropdownItem> 
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Incident </i></CDropdownItem>  
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Work Order </i></CDropdownItem>  
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Fuel Record</i></CDropdownItem>  
											

								</CDropdown>
							</div>

						</CCol> -->
						
      		</CRow>

			<CRow >
				<CCol lg="12">
				<CTabs variant="pills">
	      			<CTab title="Vehicle Info">
						<CRow class="mt-3">
							<CCol lg="6">
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
										<CCol lg="12">
											<strong class="p-1 mb-2 bg-light text-dark" 
													style="font-size:12px; border-radius: 5px;"> Vehicle Information
											</strong>
										</CCol>	
									</CRow>
									
									<CRow class="ml-1 mr-1">
											<CCol lg="6">
												<div class="form-group">
													<small class="">Plate No</small>
													<strong class="form-text mt-0 text-capitalize">{{dataParams.plate_no != "" && dataParams.plate_no != null ? dataParams.plate_no : "-"}}</strong>
												</div>
											</CCol>
										
											<CCol lg="6">
												<div class="form-group">
												<small class="">Body No.</small>
												<strong class="form-text mt-0 text-capitalize">{{dataParams.body_no ? dataParams.body_no : "-"}}</strong>
											</div>
											</CCol>
									</CRow>
									<CRow class="ml-1 mr-1" style="margin-top: -11px;">
											<CCol lg="6">
												<div class="form-group">
												<small class="">Vehicle Type</small>
												<strong class="form-text mt-0">{{dataParams.vehicle_type ? dataParams.vehicle_type.setting_name : "-"}}</strong>
											</div>
											</CCol>
											<CCol lg="6">
												<div class="form-group">
												<small class="">Year</small>
												<strong class="form-text mt-0">{{dataParams.year != "" && dataParams.year != null ? dataParams.year : "-"}}</strong>
											</div>
											</CCol>
									</CRow>
									<CRow class="ml-1 mr-1" style="margin-top: -11px;">
											<CCol lg="6">
												<div class="form-group">
												<small class="">Maker</small>
												<strong class="form-text mt-0">{{dataParams.maker_model ? dataParams.maker_model.setting_name : "-"}}</strong>
											</div>
											</CCol>
											<CCol lg="6">
												<div class="form-group">
												<small class="">Model</small>
												<strong class="form-text mt-0">{{dataParams.model ? dataParams.model.setting_name : "-" }}</strong>
											</div>
											</CCol>
									</CRow>	
									<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="6">
												<div class="form-group">
												<small class="">Asset No.</small>
												<strong class="form-text mt-0">{{dataParams.asset_no != "" && dataParams.asset_no != null ? dataParams.asset_no : "-"}}</strong>
												</div>
										</CCol>	

										<CCol lg="6">
											<div class="form-group">
											<small class="">Commodity Type</small>
											<strong class="form-text mt-0">{{ dataParams.commodity ? dataParams.commodity.setting_name : "-"}}</strong>
											</div>
										</CCol>
									</CRow>
								</CCard>
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
												<CCol lg="12">
													<strong class="p-1 mb-2 bg-light text-dark" 
													style="font-size:12px; border-radius: 5px;"> Specifications
											</strong>
												</CCol>	
									</CRow>
									<br>
									<CRow class="ml-1 mr-1" style="margin-top: -11px;">
											
											<CCol lg="6">
												<div class="form-group">
												<small class="">Chassis No.</small>
												<strong class="form-text mt-0">{{dataParams.chassis_no != "" && dataParams.chassis_no != null ? dataParams.chassis_no : "-"}}</strong>
												</div>
											</CCol>	
										
									
											<CCol lg="6">
												<div class="form-group">
												<small class="">Color</small>
												<strong class="form-text mt-0">{{dataParams.color != "" && dataParams.color != null ? dataParams.color : "-"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Engine No.</small>
												<strong class="form-text mt-0">{{dataParams.engine_no != "" && dataParams.engine_no != null ? dataParams.engine_no : "-"}}</strong>
												</div>
											</CCol>	
										
										
											<CCol lg="6">
												<div class="form-group">
												<small class="">Engine Size</small>
												<strong class="form-text mt-0">{{dataParams.engine_size != "" && dataParams.engine_size != null ? dataParams.engine_size : "-"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Fuel Type</small>
												<strong class="form-text mt-0">{{dataParams.fuel_type ? dataParams.fuel_type : "-"}}</strong>
												</div>
											</CCol>	
									
											<CCol lg="6">
												<div class="form-group">
												<small class="">Transmission Type</small>
												<strong class="form-text mt-0">{{dataParams.transmission_type ? dataParams.transmission_type : "-"}}</strong>
												</div>
											</CCol>	
										
									</CRow>
								</CCard>
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
										<CCol lg="12">
											<strong class="p-1 mb-2 bg-light text-dark" 
													style="font-size:12px; border-radius: 5px;"> Vehicle Location
											</strong>
										</CCol>	
									</CRow>
									<CRow class="ml-1 mr-1">
											<CCol lg="6">
												<div class="form-group">
												<small class="">Company Owned?</small>
												<strong class="form-text mt-0 text-capitalize">{{dataParams.company_owned == 1 ? "Yes" : "No"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Subcon</small>
												<strong class="form-text mt-0">{{ dataParams.vendor_id ? dataParams.vendor.vendor_name : "-"}}</strong>
												</div>
											</CCol>	
									</CRow>
									<CRow class="ml-1 mr-1" style="margin-top: -11px;">
											
											<CCol lg="6">
												<div class="form-group">
												<small class="">With GPS?</small>
												<strong class="form-text mt-0">{{dataParams.with_gps != "" && dataParams.with_gps != null ? (dataParams.with_gps == 1 ? "Yes" : "No") : "-"}}</strong>
												</div>
											</CCol>
											<CCol lg="6">
												<div class="form-group">
												<small class="">Odometer</small>
												<strong class="form-text mt-0">{{ "-"}}</strong>
												</div>
											</CCol>
											
										</CRow>
										<CRow class="ml-1 mr-1" style="margin-top: -11px;">
											
											<CCol lg="6">
												<div class="form-group">
												<small class="">Origin</small>
												<strong class="form-text mt-0">{{dataParams.origin ? dataParams.origin.setting_name : "-"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Depot</small>
												<strong class="form-text mt-0">{{dataParams.location_label != "" && dataParams.location_label != null ? dataParams.location_label.setting_name : "-" }}</strong>
												</div>
											</CCol>	
							
											
										</CRow>			
								</CCard>
								
							</CCol>	
											
							<CCol lg="6">
								<CCard class="p-1 mb-2" v-if=!is_trucking()>
									<CRow class="p-1">
										<CCol lg="12">
											<strong class="p-1 mb-2 bg-light text-dark" 
													style="font-size:12px; border-radius: 5px;"> Project Information
											</strong>
										</CCol>	
									</CRow>
									
										<CRow class="ml-1 mr-1">
											<CCol lg="12">
												<div class="form-group">
												<small class="">Project/Dept</small>
												<strong class="form-text mt-0">{{dataParams.project ? dataParams.project.customer_name : "-"}}</strong>
												</div>
											</CCol>	
										</CRow>
										<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										
											<CCol lg="6">
												<div class="form-group">
												<small class="">Current Location</small>
												<strong class="form-text mt-0">{{dataParams.current_location != "" && dataParams.current_location != null ? dataParams.current_location.customer_name : "-" }}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Transfer Date</small>
												<strong class="form-text mt-0">{{dataParams.transfer_date != "" && dataParams.transfer_date != null ? dataParams.transfer_date : "-"}}</strong>
												</div>
											</CCol>	
											
											
										</CRow>
									
								</CCard>
								
								<CCard class="p-1">
								
								<CRow class="p-1 mb-2" >
												<CCol lg="6">
													<strong class="p-1 mb-2 bg-light text-dark" 
													style="font-size:12px; border-radius: 5px;"> Other Details
											</strong>
											</CCol>	
								</CRow>
								<CRow class="ml-1 mr-1">
											
											<CCol lg="6">
												<div class="form-group">
												<small class="">Acquisition Date</small>
												<strong class="form-text mt-0">{{dataParams.acquisition_date != "" && dataParams.acquisition_date != null ? dataParams.acquisition_date : "-"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Acquisition Price</small>
												<strong class="form-text mt-0">{{dataParams.acquisition_price != "" && dataParams.acquisition_price != null ? dataParams.acquisition_price : "-"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">Breakdown Date</small>
												<strong class="form-text mt-0">{{dataParams.breakdown_date && dataParams.breakdown_date != "" ? dataParams.breakdown_date : "-"}}</strong>
												</div>
											</CCol>	
									</CRow>
									<CRow class="ml-1 mr-1" style="margin-top: -11px;">
											
											<CCol lg="6">
												<div class="form-group">
												<small class="">CR Date</small>
												<strong class="form-text mt-0">{{dataParams.cr_date != "" && dataParams.cr_date != null ? dataParams.cr_date : "-"}}</strong>
												</div>
											</CCol>	
											<CCol lg="6">
												<div class="form-group">
												<small class="">CR Number</small>
												<strong class="form-text mt-0">{{dataParams.cr_no && dataParams.cr_no != "" ? dataParams.cr_no : "-"}}</strong>
												</div>
											</CCol>	
									</CRow>
								
									
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										
										<CCol lg="12">
											<div class="form-group">
											<small class="">Insurance Provider</small>
											<strong class="form-text mt-0">{{dataParams.insurance_provider != "" && dataParams.insurance_provider != null ? dataParams.insurance_provider : "-"}}</strong>
											</div>
										</CCol>	
								</CRow>
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="6">
											<div class="form-group">
											<small class="">Insurance Policy No.</small>
											<strong class="form-text mt-0">{{dataParams.insurance_policy_no && dataParams.insurance_policy_no != "" ? dataParams.insurance_policy_no : "-"}}</strong>
											</div>
										</CCol>	
										<CCol lg="6">
											<div class="form-group">
											<small class="">Insurance Expiry Date</small>
											<strong class="form-text mt-0">{{dataParams.insurance_expiry != "" && dataParams.insurance_expiry != null ? dataParams.insurance_expiry : "-"}}</strong>
											</div>
										</CCol>	
									
								</CRow>
					
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="6">
											<div class="form-group">
											<small class="">Inland Marine Policy No.</small>
											<strong class="form-text mt-0">{{dataParams.in_marine_policy_no != "" && dataParams.in_marine_policy_no != null ? dataParams.in_marine_policy_no : "-"}}</strong>
											</div>
										</CCol>	
										<CCol lg="6">
											<div class="form-group">
											<small class="">Inland Marine Expiry Date</small>
											<strong class="form-text mt-0">{{dataParams.in_marine_expiry != "" && dataParams.in_marine_expiry != null ? dataParams.in_marine_expiry : "-"}}</strong>
											</div>
										</CCol>
								</CRow>
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="6">
											<div class="form-group">
											<small class="">LTFRB Case No.</small>
											<strong class="form-text mt-0">{{dataParams.ltfrb_case_no != "" && dataParams.ltfrb_case_no != null ? dataParams.ltfrb_case_no : "-"}}</strong>
											</div>
										</CCol>	
										<CCol lg="6">
											<div class="form-group">
											<small class="">LTFRB Expiry Date</small>
											<strong class="form-text mt-0">{{dataParams.ltfrb_expiry != "" && dataParams.ltfrb_expiry != null ? dataParams.ltfrb_expiry : "-"}}</strong>
											</div>
										</CCol>	
									
								</CRow>
					
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="6">
											<div class="form-group">
											<small class="">M.V. File No.</small>
											<strong class="form-text mt-0">{{dataParams.mv_file_no != "" && dataParams.mv_file_no != null ? dataParams.mv_file_no : "-"}}</strong>
											</div>
										</CCol>	
										<CCol lg="6">
											<div class="form-group">
											<small class="">OR Number</small>
											<strong class="form-text mt-0">{{dataParams.or_no != "" && dataParams.or_no != null ? dataParams.or_no : "-"}}</strong>
											</div>
										</CCol>	
								</CRow>
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
									<CCol lg="6">
											<div class="form-group">
											<small class="">OR Date</small>
											<strong class="form-text mt-0">{{dataParams.or_date != "" && dataParams.or_date != null ? dataParams.or_date : "-"}}</strong>
											</div>
										</CCol>	
									<CCol lg="6">
											<div class="form-group">
											<small class="">PA Expiry Date</small>
											<strong class="form-text mt-0">{{dataParams.pa_date != "" && dataParams.pa_date != null ? dataParams.pa_date : "-"}}</strong>
											</div>
										</CCol>	
								</CRow>
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="6">
												<div class="form-group">
												<small class="">Registration Type</small>
												<strong class="form-text mt-0 text-capitalize">{{dataParams.registration_type ? dataParams.registration_type.split('_').join(' ').toUpperCase() : "-"}}</strong>
												</div>
											</CCol>	
										
										<CCol lg="6">
											<div class="form-group">
											<small class="">Registration Date</small>
											<strong class="form-text mt-0">{{dataParams.registration_date != "" && dataParams.registration_date != null ? dataParams.registration_date : "-"}}</strong>
											</div>
										</CCol>	
									
								</CRow>
								<CRow class="ml-1 mr-1" style="margin-top: -11px;">
										<CCol lg="12">
											<div class="form-group">
											<small class="">Remarks</small>
											<strong class="form-text mt-0">{{dataParams.remarks != "" && dataParams.remarks != null ? dataParams.remarks : "-"}}</strong>
											</div>
										</CCol>	
									
								</CRow>
								</CCard>
							</CCol>
							
							
						</CRow>
							
							<!-- PHOTOS -->
							<CRow class="p-1 mb-2 bg-secondary text-dark">
											<CCol lg="6">
											<strong style="font-size:12px">PHOTOS</strong>
										</CCol>	
							</CRow>
								<CRow class="ml-1 mr-1">
									<CCol lg="3">
										<!-- <label for="default_or_photo" class="ml-4">OR</label><br> -->
										<label for="default_or_photo" class="ml-2"> OR <br>
											<template v-if="dataParams.or_photo_s3 == 0">
												<img style="max-height: 200px; max-width:200px;" id="display_default_or_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.or_photo">
												<img style="max-height: 200px; max-width:200px;" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.or_photo" v-else>
											</template>
											<template v-else>
												<img style="max-height: 200px; max-width:200px;" :src="dataParams.or_photo_s3">
											</template>
										</label>
									</CCol> 
									<CCol lg="3">
										<!-- <label for="default_cr_photo" class="ml-4">CR</label><br> -->
										<label for="default_cr_photo"  class="ml-2"> CR <br>
											<template v-if="dataParams.cr_photo_s3 == 0">
												<img style="max-height: 200px; max-width:200px;" id="display_default_cr_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.cr_photo">
												<img style="max-height: 200px; max-width:200px;" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.cr_photo" v-else>
											</template>
											<template v-else>
												<img style="max-height: 200px; max-width:200px;" :src="dataParams.cr_photo_s3">
											</template>
										</label>
									</CCol> 
									<CCol lg="3">
										<!-- <label for="default_insurance_photo" class="ml-4">Insurance</label><br> -->
										<label for="default_insurance_photo" class="ml-2"> Insurance <br>
											<template v-if="dataParams.insurance_photo_s3 == 0">
												<img style="max-height: 200px; max-width:200px;" id="display_default_insurance_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.insurance_photo">
												<img style="max-height: 200px; max-width:200px;" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.insurance_photo" v-else>
											</template>
											<template v-else>
												<img style="max-height: 200px; max-width:200px;" :src="dataParams.insurance_photo_s3">
											</template>
										</label>
									</CCol> 
									<CCol lg="3">
										<!-- <label for="default_insurance_photo" class="ml-4">Insurance</label><br> -->
										<label for="default_pa_photo" class="ml-2"> PA <br>
											<template v-if="dataParams.pa_photo_s3 == 0">
												<img style="max-height: 200px; max-width:200px;" id="display_default_pa_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.pa_photo">
												<img style="max-height: 200px; max-width:200px;" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.pa_photo" v-else>
											</template>
											<template v-else>
												<img style="max-height: 200px; max-width:200px;" :src="dataParams.pa_photo_s3">
											</template>
										</label>
									</CCol> 
								</CRow>
							
								
					</CTab>
					<CTab title="Odometer History">
						<OdometerData />

					</CTab>
					<CTab title="Fuel Records">
						<FuelRecords />

					</CTab>
					<CTab title="Renewal Reminders">
						<RenewalReminders />

					</CTab>
					<CTab title="Work Order">
						<WorkOrder />

					</CTab>
					<!-- <CTab title="Renewal Reminders" @click.native="tabChanged($event, null)">
					</CTab>
					<CTab title="Service Reminders" @click.native="tabChanged($event, null)">
					</CTab>
					<CTab title="Work Orders" @click.native="tabChanged($event, null)">
					</CTab>
					<CTab title="Fuel History" @click.native="tabChanged($event, null)">
					</CTab> -->
				</CTabs>
			</CCol>		
			</CRow>

		</CCard> 
		<!-- <OdometerModal @reloadodo="reloadOdometer" :details="odoParams" @showModal="odoModal = $event; getData();" :showModal="odoModal" />  -->
		 <!-- <UpdateOdoModal :details="selectedRow" @showModal="updateOdoModal = $event; reload();" :showModal="updateOdoModal" /> -->
		<!-- <UpdateOdoModal :details="dataParams" @showModal="approveModal = $event; reload();" :showModal="approveModal" /> -->
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
// import UpdateOdoModal from './update_odometer';
import '../../style.css';
import moment from 'moment';
// import OdometerModal from './odometer_modal'
import { classDeclaration } from '@babel/types';
import OdometerData from './odometer_data'
import FuelRecords from './fuel_records_tab'
import RenewalReminders from './renewal_reminders_tab'
import WorkOrder from './work_order_tab'

export default {
	mounted(){  

		this.getData();
		
	},
	data(){
		return{
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			odoParams: {},
			odoModal: false,
			dataParams: {
				vehicle_type_id: "",
				maker_model_id: "",
				origin_id: "",
				vendor_id: "",
				driver_id: "",
				body_no: "",
				year: "",
				with_gps: "",
				company_owned: true,
				plate_no: "",
				registration_date: "",
				asset_no: "",
				engine_no: "",
				engine_size: "",
				chassis_no: "",
				transmission_type: "",
				transfer_date: "",
				color: "",
				fuel_type: "",
				later_renewal_date: "",
				acquisition_date: "",
				acquisition_price: "",
				remarks: "",
				vehicle_photo: "",
				or_photo: "",
				cr_photo: "",
				insurance_photo: "",
				status: "active", 
				location: "",
				pa_date:'',
				pa_photo:'',
			},
			editMode: false,
			selectedRow: {},
			
			isLoading: false,
			uploadPercentage: 0,
			config,
			reload_odometer : 0
		}
	},
	name: 'Tables', 
	components: { Search, Datepicker, vSelect, classDeclaration, OdometerData ,FuelRecords,RenewalReminders,WorkOrder}, 
	methods: { 
			reload(){
			setTimeout(()=>{
				this.getData();
			},500)
		},
		reloadOdometer() {
			this.reload_odometer++;
		},
		openOdoModal(){
			// this.odoModal = true;
			// this.odoParams = this.dataParams;
			this.$router.push('/data/odometer/'+this.$route.params.id)
		},

		getData(){
			this.$showLoading(true)

			axios.get(config.api_path+"/vehicle/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				this.dataParams = response.data.data
			})
			.catch(err => {
			this.$showLoading(false)
		}) 

		}, 
			 UpdateOdoModal(item){
	    	this.updateOdoModal = true;
	    	this.selectedRow = item;
	    },


	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },

	    getStatus(status){

	    	if(status == "active"){return "Active";}
	    	if(status == "inactive"){return "Inactive";}
	    	if(status == "incoming"){return "Incoming";}
	    	if(status == "archive"){return "Archive/Sold";} 

	    },

	    transimission(trans){
	    	if(trans == "manual"){return "Manual";}
	    	if(trans == "automatic"){return "Automatic";}
	    },

	    fuelType(fuel){
	    	if(fuel == "gasoline"){return "Gasoline";}
	    	if(fuel == "diesel"){return "Diesel";}
	    },

	    backToTable(){
	    	this.$router.push('/data/vehicle')
	    }

 	}
}
</script>
